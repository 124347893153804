<script>
/**
 * @Author: Andrea Dittler | webspezi.com
 * @Date:   2023-04-03 19:58:45
 * @Last Modified by:   Andrea Dittler | webspezi.com
 * @Last Modified time: 2024-08-12 09:29:25
 */

</script>

<template>
  <div class="bg" data-srcalt="/bg.webp">
    <img src="/bg.webp" alt="background" />
  </div>
  <div ref="wasserglas" id="wasserglas"></div>
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand" href="https://webspezi.com" target="_blank"><img alt="logo" src="./assets/logo.svg"
          width="300"></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link">Home</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://ipspezi.com">IP-Service</a>
          </li>
          <li class="nav-item">
            <router-link to="/impressum" class="nav-link">Impressum</router-link>
          </li>

        </ul>

      </div>
    </div>
  </nav>
  <div class="container">
    <router-view />
  </div>
</template>

<style lang="scss">
.bg {

  position: fixed;
  height: calc(100vh);
  z-index: -5;
  overflow: hidden;


  img {
    min-width: 100vw;
    min-height: 100vh;
  }
}

#wasserglas {
  position: fixed;
  filter: blur(5px);
  padding: 25px;
  border-radius: 10px;
}

.milchglas {
  margin-top: 140px;

  .iconbox {
    max-width: 100%;
  }

  .innen {
    border-radius: 10px;
    padding: 25px;
    color: #3a3a3a;
    text-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    background-size: 100%;

    background-color: rgba(255, 255, 255, .3);



    box-shadow: 0 3px 6px rgba(0, 0, 0, .2);

    aspect-ratio: 1/1;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;

    a {
      color: #3a3a3a
    }

    &:hover {
      background-color: rgba(255, 255, 255, .7);
      color: #000000;
      text-shadow: none;

      a {
        color: #000;
      }

      transition: all 0.3s ease-in-out;
    }
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 18px;

  h1 {
    margin-bottom: 50px;
  }

  .light-special {
    color: #fff;
    border-color: #fff;

    &:after {
      border-top-color: #fff;
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
    }
  }

  .special-heading {
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
    margin-bottom: 0px;
    padding: 5px 10px;
    border: 3px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    display: inline-block;
    position: relative;

    &:after {
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      bottom: -23px;
      left: 50%;
      margin-left: -10px;
      border-width: 10px;
      border-style: solid;
      background: 0 0;
    }
  }
}

a.revmail {
  unicode-bidi: bidi-override;
  direction: rtl;
}

a.revmail,
p.revmail>a {
  unicode-bidi: bidi-override;
  direction: rtl;
  white-space: nowrap;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-active {
      color: #ffffff;
    }

    &.nav-link:focus,
    &.nav-link:hover {
      color: #ffffff;
    }
  }
}
</style>
